<template>
  <div class="all-cont">
    <div class="cont-its" > 
        <div class="title"><div class="red-xing">*</div> 应用名称：</div>
        <div><Input class="inp-wid" placeholder="请输入应用名称" v-model="formdata.name"/></div>
    </div>
    <!-- <div class="cont-its" > 
        <div class="title"> <div class="red-xing">*</div>应用图片：</div>
        <div v-if="!formdata.product_imgs">
            <Upload
                :format="['jpg','jpeg','png','gif']"
                :on-format-error="handleFormatError"
                :on-success="uploadImgSuccess"
                :on-progress="handleProgress"
                :show-upload-list="false"
                :headers="headers"
                :action="uploadImg">
                <div class="uplads_box">
                <div class="center">
                    <div style="margin-top:-10px;text-align: center;"><Icon style="color:#9EA6B9" size="70" type="ios-add" /></div>
                    <div class="font14" style="text-align: center;margin-top:-10px;color:#9CA7B9">点击上传</div>
                    <div class="fontcu" style="color:#9CA7B9">建议尺寸:150*150</div>
                </div>
                </div>
            </Upload>
        </div>
        <div class="logo-pic" v-else>
            <div class="clear-pic"  @click="clearLogo"><Icon type="ios-close-circle-outline" size="30" /></div>
            <img :src="formdata.product_imgs">
        </div>
    </div> -->
    <div class="cont-itsf" > 
        <div class="title"><div class="red-xing">*</div> 应用简介：</div>
        <div><Input class="inp-wid" placeholder="请输入应用简介" type="textarea" :autosize="{minRows: 2,maxRows: 5}" v-model="formdata.introduction"/> </div>
    </div>    
    <div class="cont-its" > 
        <div class="title"><div class="red-xing">*</div> 应用权限：</div>
        <div>
            <!-- <Select v-model="formdata.permissions_id"  style="width:200px">
                <Option v-for="(item,index) in permissionsList" :value="index" :key="item.id">{{ item.permissions_name }}</Option>
            </Select> -->
            <Select   v-model="formdata.permissions_id" style="width: 200px" @on-change="restprice" placeholder="请输入应用权限">
                <Option  v-for="item in permissionsList" :value="item.id" :key="item.id">{{ item.permissions_name }}</Option>
            </Select>
        </div>
    </div>
    <!-- <div class="cont-its" > 
        <div class="title"><div class="red-xing">*</div> 应用类别：</div>
        <div>
            <Select   v-model="typeId" style="width: 200px" @on-change="restprice" placeholder="请输入应用类别">
                <Option  v-for="item in typeList" :value="item.id" :key="item.id">{{ item.typeName }}</Option>
            </Select>
        </div>
    </div> -->
    <div class="cont-itsf" > 
        <div class="title"><div class="red-xing">*</div> 应用价格：</div>
        <div>
            <div class="pice-ites" v-for="(item,index) in formdata.price_group" :key="index">
                 <Input class="inp-prd" placeholder="请输入应用价格" type="number" v-model="item.price"/> 
                 原价：
                 <Input class="inp-prd" placeholder="请输入原价" type="number" v-model="item.original_price"/>
                 <div v-if="formdata.permissions_id == 1000">
                     <span class="red-xing">*</span>数量：
                   <Input class="inp-prd" placeholder="请输入数量" type="number" v-model="item.number"/>
                 </div>
                 <img v-if="index!=0" class="cha-cla" @click="removePic(index)" src="@/assets/tangimg/chacha.png">
            </div>        
            <div style="margin-top:10px"> <Button @click="addPic()" style="margin-right: 5px;width:60px">+</Button> 最多添加十个</div> 
        </div>
        
    </div>
    <!-- <div class="cont-its" > 
        <div class="title"><div class="red-xing">*</div> 详情图片：</div>
        <div v-if="!formdata.detail_imgs">
            <Upload
                :format="['jpg','jpeg','png','gif']"
                :on-format-error="handleFormatError"
                :on-success="uploadImgSuccessdeta"
                :on-progress="handleProgress"
                :show-upload-list="false"
                :headers="headers"
                :action="uploadImg">
                <div class="uplads_box">
                <div class="center">
                    <div style="text-align: center;margin-top:-10px"><Icon style="color:#9EA6B9" size="70" type="ios-add" /></div>
                    <div class="font14" style="margin-top:-10px;text-align: center;color:#9CA7B9">点击上传</div>
                    <div class="fontcu" style="color:#9CA7B9">建议尺寸:150*150</div>
                </div>
                </div>
            </Upload>
        </div>
        <div class="logo-pic" v-else>
            <div class="clear-pic"  @click="clearLogoT"><Icon type="ios-close-circle-outline" size="30" /></div>
            <img :src="formdata.detail_imgs">
        </div>
    </div> -->
    <div class="cont-itsf" > 
        <div class="title"> 详情信息：</div>        
        <editor :edtor-con="formdata.details_information" :index="1" @getContent="getEnContent" @getContentchange="getEnContentchange" style="width:800px"/>        
    </div>
    <Divider />
    <div class="footer">
        <Button v-throttle   type="primary" @click="submit"  style="margin-right: 5px">确认</Button>     
        <Button style="margin-right: 5px" @click="removeForm">重置</Button>
    </div>
  </div>
</template>

<script>
import editor from "@/components/editor/editor.vue";
import config from '@/config/index';
import {get_permissions_list,update_application,get_appcenter_info} from "@/api/appcenter";
import {getCookies} from '@/utils/helper'
export default {
    beforeRouteEnter:(to,from,next) => {
        if(to.query.run == 'add') {
            to.meta.title = '应用中心 / 创建'
        }else if(to.query.run == 'edit') {
            to.meta.title = '应用中心 / 编辑'
        }
        next()
    },
    name: "appCenterInfo",
    components: { editor },
    data(){
        return{
            formdata:{
                name: "", //应用名称
                product_imgs: "",//应用图片
                introduction: "",//应用简介
                permissions_id: "", //应用权限
                price_group: [{number:'',price:"",original_price: '',id:''}], //应用价格
                detail_imgs: '',//详情图片
                details_information: '', //详情信息
                plug_id : '', //修改
            },
            typeId: '',
            // typeList:[
            //     {id: 1,typeName: '关于合同'},
            //     {id: 2,typeName: '关于短信'}, 
            //     {id: 3,typeName: '个性化'},
            // ],
            permissionsList:[],//权限列表数组
            uploadImg:config.apiUrl + '/adminapi/config/fileUpload',  
            headers:{'Authori-zation' : getCookies('token')},
        }
    },
    created(){
        if(this.$route.query.run!='add'){
            this.get_appcenter_info(this.$route.query.id)
            
        }
        this.get_permissions_list()
    },
    methods: {
        //获取权限
        get_permissions_list(){
            get_permissions_list().then(res => {
                this.permissionsList = res.data.data
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        //获取详情
        get_appcenter_info(e){
            get_appcenter_info({id:e}).then(res => {
                this.formdata = res.data
                this.formdata.price_group.forEach(item=>{
                    if(item.number == 0){
                        item.number = ''                    
                    }
                    if(item.original_price == 0){
                        item.original_price = ''
                    }
                })
                this.formdata.plug_id = this.$route.query.id
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        restprice() {
            this.formdata.price_group = []
            this.formdata.price_group.push({number:'',price:"",id:'',original_price:''})
        },
        addPic(){
            console.log('this.formdata.permissions_id',this.formdata.permissions_id)
            if(this.formdata.permissions_id == '') return this.$Message.error("请先选择应用权限")
            // if(this.formdata.permissions_id == '999') return this.$Message.error("短信签名只需填写一组价格")
            // if(this.formdata.permissions_id == '998') return this.$Message.error("短信模板只需填写一组价格")
            if(this.formdata.permissions_id != '1000') return this.$Message.error("只需填写一组价格")
            if(this.formdata.price_group.length>9){
                return this.$Message.error("最多添加十个")
            }else {
                this.formdata.price_group.push({number:'',price:"",id:'',original_price:''})
            }
        },
        removePic(e){
            this.formdata.price_group.splice(e,1)  
        },
        submit(){        
            console.log('this.formdata',this.formdata)   
            if( this.formdata.name == "") return this.$Message.error('请填写应用名称')
            // if( this.formdata.product_imgs == "") return this.$Message.error('请填写 应用图片')
            if( this.formdata.introduction == "") return this.$Message.error('请填写 应用简介')
            if( this.formdata.permissions_id == "") return this.$Message.error('请选择 应用权限')
            // if( this.formdata.detail_imgs == "") return this.$Message.error('请填写 详情图片')
            let a = false
            this.formdata.price_group.forEach((item,index)=>{
                if(this.formdata.permissions_id == '1000'){
                if(item.number == '') {
                    a = true
                }
                if(item.price == '') {
                    a = true
                }               
                }else{
                    if(item.price == '') {
                        a = true
                    } 
                item.number = 0
                }
                item.id = index+1
            })
            if(a) return this.$Message.error('请填写 应用价格')
            this.formdata.price_group.forEach( item =>{
                if(item.number == ''|| item.number == null) {
                    item.number = 0
                }  
                if(item.original_price == ''|| item.original_price == null) {
                    item.original_price = 0
                }  
            })
            update_application(this.formdata).then(res => {
                this.$Message.success(res.msg)
                this.$router.push({path: '/admin/appCenter'})            
                this.removeForm()
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        removeForm(){
            this.formdata.name = "" //应用名称
            this.formdata.product_imgs = ""//应用图片
            this.formdata.introduction = ""//应用简介
            this.formdata.permissions_id = "" //应用权限
            this.formdata.price_group = [] //应用价格
            this.formdata.price_group.push({number:'',price:"",id:'',original_price:''})
            this.formdata.details_information = '' //详情信息
            this.formdata.plug_id = '' //修改
            this.formdata.detail_imgs = ''
        },

        //文件上传类型错误
        handleFormatError () {
            this.$Message.warning('暂不支持上传此格式');
        },
        //添加图片上传图片成功
        uploadImgSuccess (e) {
            this.formdata.product_imgs = e.data
            this.$Message.destroy()
            this.$Message.success(e.msg);
        },  
        uploadImgSuccessdeta (e) {
            this.formdata.detail_imgs = e.data
            this.$Message.destroy()
            this.$Message.success(e.msg);
        },        
        // 文件上传时
        handleProgress(){
            const msg = this.$Message.loading({
                content: '上传中...',
                duration: 0
            });
            setTimeout(msg, 3000);
        },
        clearLogo(){
            this.formdata.product_imgs = ''
        },
        clearLogoT(){
            this.formdata.detail_imgs = ''
        },
        //富文本
        getEnContentchange(detailData) {
            this.formdata.details_information = detailData.editorData;
        },
        getEnContent(detailData) {
            this.formdata.details_information = detailData.editorData;
        },
    }

}
</script>

<style scoped>
.pice-ites{
    margin-bottom: 10px ;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.all-cont {
    width: 100%;
}
.cont-its {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top:20px
}
.cont-itsf {
    display: flex;
    justify-content: flex-start;
    margin-top:30px
}
.red-xing {
    color: red;
    margin-right: 5px;
    font-weight: bold;
}
.title{
    display: flex;
    text-align: end;
    justify-content: flex-end;
    width:120px;
    user-select: none;
}
.footer button{
    width: 65px;
    margin: 0 40px
}
.inp-wid {
    width: 350px;
}
.inp-prd {
    width: 150px;
    margin-right: 10px;
}
.cha-cla {
    width:24px;
    height:24px;
    cursor: pointer;
}
.uplads_box{
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px dashed  #A0A4BD;
    background: #F7F8FA;
    border-radius: 5px;
    cursor: pointer;
}
.fontcu {
    font-size: 12px;
    text-align: center;
    margin-top:3px ;
}
.logo-pic {
    width: 105px;
    height: 105px;
    position: relative;
}
.logo-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}
.clear-pic {
    position: absolute;
    top: -14px;
    right: -14px;
    z-index: 9999;
    color: #A0A4BD;
    cursor: pointer;
}

</style>